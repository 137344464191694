import { PAGE_URI_SEO_SUFFIX, PageId } from '../constants';

export async function removeBookingCalendarPageSuffixStatics(
  editorSDK,
  appToken,
  allSitePages,
) {
  const bookingCalendarPage = allSitePages.find(
    (page) => page.tpaPageId === PageId.BOOKINGS_CALENDAR_PAGE,
  );
  if (bookingCalendarPage) {
    await removeSuffixPageUri(editorSDK, appToken, bookingCalendarPage);
  }
}

export async function removeServicePageSuffixStatics(
  editorSDK,
  appToken,
  allSitePages,
) {
  const bookingServicePage = allSitePages.find(
    (page) => page.tpaPageId === PageId.BOOKINGS_SERVICE_PAGE,
  );
  if (bookingServicePage) {
    await removeSuffixPageUri(editorSDK, appToken, bookingServicePage);
  }
}
async function removeSuffixPageUri(editorSDK, appToken, page) {
  return editorSDK.pages.data.update(appToken, {
    pageRef: { id: page.id, type: page.type },
    data: {
      pageUriSEO: page.pageUriSEO.replace(PAGE_URI_SEO_SUFFIX, ''),
    },
  });
}
