import { IFedOpsLogger } from '@wix/native-components-infra/dist/src/types/types';
import {
  BOOKINGS_DEF_ID,
  ECOM_DEF_ID,
  experiments,
  FEDOPS_APP_NAME,
  PageId,
  WidgetsId,
} from '../constants';
import { ComponentDataType } from '../migration/domain';
import { EcomComponent } from '@wix/ecom-platform-sdk';
import {
  EditorReadyOptions,
  EditorSDK,
  EditorType,
} from '@wix/platform-editor-sdk';
import { EditorScriptApi } from '../api/api';
import { isExperimentEnabled } from './experiments';

export const createFedopsLogger = (
  editorOptions: EditorReadyOptions,
): IFedOpsLogger => {
  const fedopsLoggerFactory = editorOptions.monitoring.createFedopsLogger();
  return fedopsLoggerFactory(FEDOPS_APP_NAME);
};

export const isBookingsCheckoutInstalled = async (sdk) => {
  const allSitePages = await sdk.pages.data.getAll();
  return !!allSitePages.find(
    (page) => page.tpaPageId === PageId.BOOKINGS_CHECKOUT,
  );
};

export const isEcomCheckoutInstalled = async (sdk) => {
  const allSitePages = await sdk.pages.data.getAll();
  return !!allSitePages.find(
    (page) =>
      page.tpaPageId === EcomComponent.CHECKOUT &&
      page.appDefinitionId === ECOM_DEF_ID,
  );
};

export const isEcomThankYouPageInstalled = async (sdk) => {
  const allSitePages = await sdk.pages.data.getAll();
  return !!allSitePages.find(
    (page) =>
      (page.tpaPageId === EcomComponent.THANK_YOU_PAGE ||
        page.tpaPageId === 'thank_you_page') &&
      page.appDefinitionId === ECOM_DEF_ID,
  );
};

export const isEcomCartInstalled = async (sdk) => {
  const allSitePages = await sdk.pages.data.getAll();
  return !!allSitePages.find(
    (page) =>
      (page.tpaPageId === EcomComponent.CART ||
        page.tpaPageId === 'shopping_cart') &&
      page.appDefinitionId === ECOM_DEF_ID,
  );
};

export const isBookingsListInstalled = async (sdk) => {
  const allSitePages = await sdk.pages.data.getAll();
  return !!allSitePages.find((page) => page.tpaPageId === PageId.BOOKINGS_LIST);
};

export const isServicePageInstalled = async (sdk) => {
  const allSitePages = await sdk.pages.data.getAll();
  return !!allSitePages.find(
    (page) => page.tpaPageId === PageId.BOOKINGS_SERVICE_PAGE,
  );
};

export const isBookingCalendarInstalled = async (sdk) => {
  try {
    const allSitePages = await sdk.pages.data.getAll();
    return !!allSitePages.find(
      (page) => page.tpaPageId === PageId.BOOKINGS_CALENDAR_PAGE,
    );
  } catch (e) {
    const errorMessage = `isBookingCalendarInstalled Failed - ${
      e?.message ? e.message : JSON.stringify(e)
    }`;
    throw new Error(errorMessage);
  }
};

export const isBookingFormInstalled = async (sdk) => {
  const allSitePages = await sdk.pages.data.getAll();
  return !!allSitePages.find(
    (page) => page.tpaPageId === PageId.BOOKINGS_FORM_PAGE,
  );
};

export const isNoBookingsPagesInstalled = async (sdk) => {
  const allSitePages = await sdk.pages.data.getAll();
  const bookingsPages = allSitePages.filter(
    (page) =>
      page.tpaPageId === PageId.SCHEDULER ||
      page.tpaPageId === PageId.BOOKINGS_LIST ||
      page.tpaPageId === PageId.BOOKINGS_CHECKOUT,
  );
  return !bookingsPages.length;
};

export const getAllBookingsAppBuilderWidgets = async (sdk, appToken) => {
  const allComponentsPromises = (await getAllSiteComponents(sdk, appToken)).map(
    (componentRef) =>
      sdk.components.data
        .get('token', { componentRef })
        .then((componentData) => ({
          componentData,
          componentRef,
        })),
  );
  const bookingsTimetableWidgets = (
    await Promise.all(allComponentsPromises)
  ).filter(
    ({ componentData }) =>
      componentData &&
      componentData.appDefinitionId === appToken &&
      componentData.type === 'WidgetRef',
  );
  return bookingsTimetableWidgets;
};
const getAllSiteRouters = async (sdk, appToken) => {
  return sdk.document.routers.getAll(appToken, {
    appDefinitionId: BOOKINGS_DEF_ID,
  });
};
export const getBookingsRouters = async (
  sdk,
  appToken,
  deprecatedOnly = false,
) => {
  const allRoutersPromises = (await getAllSiteRouters(sdk, appToken))
    .filter(
      (router) =>
        !deprecatedOnly ||
        !router.pages.length ||
        router.pages.every(
          ({ pageRoles }) =>
            !pageRoles.includes(PageId.BOOKINGS_CALENDAR_PAGE) &&
            !pageRoles.includes(PageId.BOOKINGS_SERVICE_PAGE),
        ),
    )
    .map((router) =>
      sdk.document.routers.getByPrefix(appToken, { prefix: router.prefix }),
    );
  return Promise.all(allRoutersPromises);
};
export const getStateBoxByBookingsAppBuilderWidget = async (
  sdk,
  appToken,
  bookingsAppBuilderWidgetComponentRef,
) => {
  const appBuilderWidgetAllComponents = (
    await sdk.document.components.getChildren(appToken, {
      componentRef: bookingsAppBuilderWidgetComponentRef,
      recursive: true,
    })
  ).map((componentRef) =>
    sdk.components
      .getType(appToken, { componentRef })
      .then((componentType) => ({
        componentType,
        componentRef,
      })),
  );

  const stateBoxComponent = (
    await Promise.all(appBuilderWidgetAllComponents)
  ).filter(
    ({ componentType }) =>
      componentType === 'wysiwyg.viewer.components.StateBox',
  );
  return (stateBoxComponent.pop() as any).componentRef;
};

export const onRemoveApp = async (sdk, appToken) => {
  try {
    const componentsToDelete = await getAllBookingsAppBuilderWidgets(
      sdk,
      appToken,
    );
    await Promise.all(
      componentsToDelete.map(({ componentRef }) =>
        sdk.document.components.remove('token', { componentRef }),
      ),
    );
    const [removeRoutersOnRemoveAppEnable, isBookingsUninstallEnable] =
      await Promise.all([
        isExperimentEnabled(experiments.BOOKINGS_REMOVE_ROUTERS_REMOVE_APP),
        isExperimentEnabled(experiments.BOOKINGS_UNINSTALL),
      ]);
    if (removeRoutersOnRemoveAppEnable) {
      const routersToDelete = await getBookingsRouters(sdk, appToken);
      await Promise.all(
        routersToDelete.map((routerRef) =>
          sdk.document.routers.remove(appToken, { routerRef }),
        ),
      );
    }
    if (isBookingsUninstallEnable) {
      sdk.application.uninstall(appToken);
    }
    return 'OK';
  } catch (e) {
    console.error('Failed to remove Ref Widgets', e);
    return 'Error';
  }
};

export const isBookingInstalled = async (sdk) =>
  sdk.document.application.isApplicationInstalled(BOOKINGS_DEF_ID, {
    appDefinitionId: BOOKINGS_DEF_ID,
  });
export const isEcomInstalled = async (sdk) =>
  sdk.document.application.isApplicationInstalled(ECOM_DEF_ID, {
    appDefinitionId: ECOM_DEF_ID,
  });

export const getBookingsDefId = (sdk) => sdk.info.getAppDefinitionId();

export const getBookingsData = async (sdk, appToken) =>
  sdk.document.tpa.app.getDataByAppDefId(appToken, await getBookingsDefId(sdk));

export const getEditorSdkSrc = (sdk) => sdk.info.getSdkVersion().scriptSrc;

export const updateComponentStyle = (sdk, appToken, compId, style) =>
  sdk.document.components.style.update(appToken, {
    componentRef: { type: 'DESKTOP', id: compId },
    style,
  });

export const updateComponentData = (sdk, appToken, componentRef, data) =>
  sdk.document.components.data.update(appToken, { componentRef, data });

export const updatePageData = (sdk, appToken, pageRef, data) =>
  sdk.pages.data.update(appToken, { pageRef, data });

export const getComponentData = (sdk, appToken, componentRef) =>
  sdk.document.components.data.get(appToken, { componentRef });

export const getPageData = (sdk, appToken, pageRef) =>
  sdk.document.pages.getPageData(appToken, { pageRef });

export const getComponentRefById = (sdk, appToken, compId) =>
  sdk.document.components.getById(appToken, { id: compId });

export const getAllBookingsPages = (sdk, appToken) =>
  sdk.document.pages.getApplicationPages(appToken);

export const navigateToPage = (sdk, appToken, pageId) =>
  sdk.document.pages.navigateTo(appToken, {
    pageRef: { type: 'DESKTOP', id: pageId },
  });

export const getAllSiteComponents = async (sdk, appToken) => {
  try {
    const allComponents = (
      await Promise.all(
        (
          await sdk.pages.data.getAll()
        ).map(async (page) => {
          const componentRef = await sdk.components.getById('', {
            id: page?.id,
          });
          const childrenRef = await sdk.components.getChildren('', {
            componentRef,
            recursive: true,
          });

          if (childrenRef?.length) {
            return [...childrenRef, ...[componentRef]];
          }

          return componentRef;
        }),
      )
    ).flat();

    const bookingsComponents = [];

    for (const componentRef of allComponents) {
      const data = await sdk.components.data.get('', { componentRef });

      if (data?.appDefinitionId) {
        bookingsComponents.push(componentRef);
      }
    }

    return bookingsComponents;
  } catch (e) {
    const errorMessage = `errorCode: getAllSiteComponentsFunction - ${
      e?.message ? e.message : JSON.stringify(e)
    }`;
    throw new Error(errorMessage);
  }
};

export const getFullComponentStructure = async (
  sdk,
  appToken,
  componentRef,
) => {
  try {
    const result = await sdk.document.components.serialize(appToken, {
      componentRef,
      maintainIdentifiers: true,
    });

    return {
      id: componentRef.id,
      ...result,
    };
  } catch (e) {
    return {};
    /*
    const errorMessage = `errorCode: SERIALIZE_COMPONENT_FAILED - ${(e?.message ? e.message : JSON.stringify(e))}`;
    throw new Error(errorMessage);
     */
  }
};

export const getPageRefByComponentRef = (sdk, appToken, componentRef) =>
  sdk.components.getPage(appToken, { componentRef });

export const removePage = (sdk, appToken, pageId) =>
  sdk.document.pages.remove(appToken, { pageRef: { id: pageId } });

export const updateControllerConfiguration = (sdk, appToken, compId, config) =>
  sdk.document.controllers.saveConfiguration(appToken, {
    controllerRef: { type: 'DESKTOP', id: compId },
    config,
  });

export const isOldBookingsListWidgetExist = async (sdk, appToken) => {
  const allComponents = await getAllSiteComponents(sdk, appToken);
  const siteStructure: any[] = await (async () => {
    return Promise.all(
      allComponents.map((componentRef) =>
        getFullComponentStructure(sdk, appToken, componentRef),
      ),
    );
  })();

  return siteStructure.find(
    (comp) => comp?.data?.widgetId === WidgetsId.OLD_BOOKINGS_SERVICE_LIST,
  );
};

export const getTPAMainSectionStructure = async (sdk, appToken, tpaId) => {
  const allComponents = await getAllSiteComponents(sdk, appToken);
  const siteStructure: any[] = await (async () => {
    return Promise.all(
      allComponents.map((componentRef) =>
        getFullComponentStructure(sdk, appToken, componentRef),
      ),
    );
  })();

  return (
    siteStructure.find(
      (comp) =>
        comp?.data &&
        comp?.data?.appDefinitionId === tpaId &&
        comp?.data?.type === ComponentDataType.MAIN_SECTION,
    ) ||
    siteStructure.find(
      (comp) =>
        comp?.data &&
        comp?.data?.appDefinitionId === tpaId &&
        comp?.data?.widgetId === WidgetsId.BOOKINGS_LIST_PAGE,
    ) ||
    siteStructure.find(
      (comp) =>
        comp?.data &&
        comp?.data?.appDefinitionId === tpaId &&
        comp?.data?.tpaPageId === PageId.BOOKINGS_LIST,
    )
  );
};

export const getTPAMainSectionPageRef = async (sdk, appToken, tpaId) => {
  const tpaMainSectionStructure = await getTPAMainSectionStructure(
    sdk,
    appToken,
    tpaId,
  );
  const tpaMainSectionRef = await getComponentRefById(
    sdk,
    appToken,
    tpaMainSectionStructure.id,
  );
  return getPageRefByComponentRef(sdk, appToken, tpaMainSectionRef);
};

export const ecomRolloutButNotSite = async (
  sdk: EditorSDK,
  editorScriptApi: EditorScriptApi,
  appToken: string,
) => {
  const isBookingRollout = await editorScriptApi.getIsBookingsOnEcom();
  const bookingInstalled = await isBookingInstalled(sdk);
  const ecomInstalled = await isEcomInstalled(sdk);
  const bookingFormInstalled = await isBookingFormInstalled(sdk);
  const ecomCheckoutInstalled = await isEcomCheckoutInstalled(sdk);
  const ecomThankYouPageInstalled = await isEcomThankYouPageInstalled(sdk);
  const oldBookingsListWidgetExist = await isOldBookingsListWidgetExist(
    sdk,
    appToken,
  );

  return (
    isBookingRollout &&
    bookingInstalled &&
    (!bookingFormInstalled ||
      !ecomInstalled ||
      !ecomCheckoutInstalled ||
      !ecomThankYouPageInstalled ||
      !!oldBookingsListWidgetExist)
  );
};

export const isADI = (editorOptions: EditorReadyOptions) => {
  return (
    editorOptions?.origin?.type.toLowerCase()?.includes('adi') ||
    editorOptions?.origin?.type?.toLowerCase()?.includes('oneapp')
  );
};

export const isEditorX = (editorOptions: EditorReadyOptions) => {
  return EditorType.Responsive === editorOptions?.origin?.type;
};
